import React from "react";
import Img from "gatsby-image";
import {graphql} from "gatsby";
import Layout from "../templates/layout";
import Contact from "../components/contactButton";

export default (props) => {
    const data = props.data.allContentfulAboutMePage.nodes[0];
    const banner = data.banner;
    const headshot = data.img;
    const phoneNumbers = data.phoneNumbers;
    const emailAddresses = data.emailAddresses;
    const aboutMeText = data.aboutMe.aboutMe.split('---');
    console.log(data)
    return (<Layout title="About Me" des="About Marcus Moran, of Marcus Moran Insurance. I am a family man, who is dedicated to adding you to our family!">
        <div>
            {/*Banner*/}
            <div className="bg-blue-900 pb-5 md:pb-10 mb-10 relative">
                        <Img fluid={banner.sizes} alt="Banner"/>
                        <h1 className="absolute text-2xl md:text-4xl lg:text-5xl text-white text-bold italic words-xs lg:words-md">About Me</h1>
                    </div>
            {/*Pic/Info*/}
            <div className="flex-wrap sm:flex justify-center sm:justify-between max-w-xs sm:max-w-lg lg:max-w-2xl mx-auto text-center sm:text-left">
                <div className="text-center sm:text-left">
                    <Img className="mx-auto" fixed={headshot.fixed} alt="Marcus Moran; A very handsome isurance broker" />
                </div>
                <div className="sm:ml-3 text-center sm:text-right">
                    <h1 className="font-bold text-base">Marcus Moran</h1>
                    <p className="">Healthcare extraordinaire</p>
                    <div className="mt-8 font-bold">
                        {phoneNumbers.map((phoneNum)=> <p>{phoneNum}</p>)}
                        <div className="mt-6">
                            {emailAddresses.map((eAddress)=> <p className="font-normal">{eAddress}</p>)}
                        </div>
                    </div>
                </div>
            </div>

            {/*Bio*/}
            <div className="mt-10 mx-auto text-gray-600 max-w-xs sm:max-w-sm md:max-w-xl lg:max-w-2xl">
                {aboutMeText.map((el)=>{
                    return (<p><p>{el}</p><br/></p>)
                })}
            </div>

            {/*Photos */}
            <div>

            </div>
            
            {/*Scudeule Appointment*/}
            <Contact msg="Now that you know more about me, tell me more about yourself!"/>
        </div>
    </Layout>)
}

export const query = graphql`
    query{
        header: file(relativePath:{eq: "servicesbanner.jpg"}){
            childImageSharp{
                sizes(maxWidth: 900, quality:100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpSizes
                }
            }
        }

        allContentfulAboutMePage {
            nodes {
              banner {
                sizes(maxWidth: 900, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
              img {
                fixed(width: 200, height: 200, quality: 100) {
                    ...GatsbyContentfulFixed
                }
              }
              emailAddresses
              phoneNumbers
              aboutMe {
                aboutMe
              }
            }
          }

        
    }
`